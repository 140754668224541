//Slider
//Slider
.slider {
    .swiper-container {
        width: 100%;
        height: 100%;
    }
    .swiper-slide {
        text-align: center;
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 100%;
        }
    }
}

//Services
//Services
.services {
    background: $blue;
    padding: 40px 0;
    .services-img {
        min-height: 300px;
        max-height: 300px;
        img {
            width: 100%;
            min-height: inherit;
            object-fit: cover;
        }
    }
    .subject {
        text-align: center;
    }
    .content {
        .subject {
            position: relative;
            &::before {
                content: "";
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                width: 150%;
                height: 1px;
                background-color: #8cbaff;
                bottom: -10px;
            }
        }
        .description {
            margin-bottom: 60px !important;
        }
        a {
            position: absolute;
            bottom: 0;
        }
    }
}

//Application
//Application
.application {
    margin: 60px 0;
    .content {
        background: $gray300;
        padding: 40px;
        border-radius: 30px;
    }
}

//PAGE Eg
.hero-bar {
    background: $blue;
    padding: 10px 0;
}

//Blog

.blog {
    .image {
        min-width: calc(50% - 10px);
        height: 100%;
        overflow: hidden;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
         min-width: 300px;
            min-height: 300px;
            max-width: 300px;
            max-height: 300px;

        img {
            min-width: 300px;
            min-height: 300px;
            max-width: 300px;
            max-height: 300px;
            object-fit: cover;
        }
    }
    .right-blog-posts {
        height: 100%;
      
    }
    .content {
        position: relative;
        height: 100%;
        align-items: start;
        .card-content {
            height: 100%;
        }
        .card-text {
            position: absolute;
            bottom: 24px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    .card-description {
        max-height: 74px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

//MEDIA
.media {
    .swiper-container {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        text-align: center;
        font-size: 18px;

        display: flex;
        justify-content: center;
        align-items: center;
        img {
            max-width: 100%;
        }
    }
}
