// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// Extra small devices (portrait phones, less than 576px)

@media (max-width: 480px) {
    .content {
        flex-direction: column;
        .image {
            margin: 0 !important;
        }
    }
}
@media (max-width: 575.98px) {
    .blog {
        // .image {
        //     img {
        //         width: 100%;
        //     }
        // }
        .card-text {
            // display: none;
            bottom: 2px !important;
            p {
                font-size: 0.7rem !important;
            }
        }
        .bg-ym {
            .image {
            }
        }
    }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
    footer {
        .col-lg-2 {
            &:nth-child(2) {
                .list {
                    margin-left: 0px !important;
                }
            }
        }
    }
    .services {
        .col-lg-4 {
            margin-bottom: 32px;
        }
    }

    .blog {
        .bg-ym {
            margin-bottom: 32px;
        }
        .right-blog-posts {
            .content {
                .image {
                    width: 100%;
                }
            }
        }
    }
    footer {
        .col-lg-2 {
            margin-bottom: 30px !important;
        }
    }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
    header {
        .nav-menu {
            // display: none;
        }
    }
    footer {
        .left {
            max-width: 100%;
        }
        .uls {
            flex-wrap: wrap;
            .list {
                width: 50%;
                margin-bottom: 40px;
            }
        }
    }
}
