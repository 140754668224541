$twitter: #1da1f2;
$facebook: #3b5998;
$instagram: #833ab4;
$behance: #1769ff;
$linkedin: #0077b5;
$youtube: #ff0000;

/*  TEMA RENKLERİ */
$white: #fff;
$navy: #182c54;
$blue: #578cdb;
$gray-300: #ababab;
$gray-100: #ecf0f5;

$primary: $blue;
$black: #000000;

// TEXT COLOR - FAMILY
$gray300: #9fa4ac;
$gray-lvl-5: #3e3e3e;
$font-family-base: "Work Sans", sans-serif;
.bg-ym {
    border-radius: 10px;
}

$theme-colors: (
    "navy": $navy,
    "white": $white,
    "black": $black,
    "gray-300": $gray-300,
    "gray-100": $gray-100,
);

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1440px
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1440px
);

// $body-color: #000;
// $navbar-light-color: #1e1e1e;

$navbar-padding-y: 1rem;

// $breadcrumb-bg: rgba(255, 255, 255, 0.6);
// $breadcrumb-active-color: #000;
// $breadcrumb-border-radius: 20px;
// $breadcrumb-margin-bottom: 2rem;
