@import "variables";
@import "libraries/common";
@import "libraries/social_media";
@import "layout";

@import "responsive";
@import "home";

@import "bootstrap/scss/bootstrap";
@import "swiper/swiper-bundle.min.css";
@import "@fortawesome/fontawesome-free/css/all.min.css";

@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;600&display=swap");

html {
    scroll-behavior: smooth;
}

body {
    font-family: $font-family-base;
    margin: 0;
}
ul {
    padding-inline-start: 0px;
    color: transparent;
}
.styled-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='28' viewBox='0 0 28 28' width='28' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 99%;
    background-position-y: 5px;
}

.container {
    // max-width: 1220px;
    padding: 0 10px;
}
.row {
    margin: 0 -10px;
}
[class*="col-"] {
    padding: 0 10px;
}
.wrapper {
    overflow: hidden;
}
.cursor-pointer {
    cursor: pointer;
}
a {
    text-decoration: none;
}
.pis {
    padding-inline-start: 20px;
    list-style: circle;
    list-style: disc;
    color: white;
}
.w-60 {
    max-width: 60%;
}
//Title
.title {
    font-family: $font-family-base;
    font-style: normal;

    &.a-link {
        color: $gray-lvl-5;
    }
    &.f-link {
        font-size: 16px;
        line-height: 20px;
        color: white;
        &.x-size {
            font-size: 14px;
            line-height: 16px;
        }
        &.xx-size {
            font-size: 11px;
            line-height: 12px;
        }
    }
    &.section-h {
        font-size: 36px;
        line-height: 40px;
        text-align: center;
        color: $white;
    }
    &.subject {
        font-size: 18px;
        line-height: 20px;
        color: $white;
        font-weight: normal;
    }
    &.description {
        color: $white;
        font-size: 15px;
        line-height: 20px;
        text-align: center;
    }
    &.mid {
        font-size: 27px;
        color: $white;
    }
    &.card-h {
        font-size: 24px;
        line-height: 28px;
    }
    &.card-date {
        font-size: 22px;
        line-height: 27px;
    }
    &.hero-bar-t {
        font-size: 26px;
        line-height: 32px;
        color: $white;
        text-align: center;
        margin: 0;
        font-weight: normal;
    }
    &.card-description {
        font-size: 12px;
    }
    &.card-subject {
        font-size: 17px;
    }
}
//Btn
.btn {
    padding: 6px 10px !important;
    font-size: 14px;
    &.btn-white {
        font-size: 24px;
        line-height: 28px;
    }
}
.mr-30 {
    margin-right: 30px;
}
.breadcrumb-item + .breadcrumb-item::before {
    content: "|";
    color: $blue;
}

.breadcrumb-item {
    a {
        color: $blue;
        text-decoration: none;
        font-size: 18px;
        &:hover,
        &:visited {
            color: $blue !important;
        }
    }
    &.active {
        color: $navy !important;
    }
}

.navbar-brand {
    img {
        height: 60px;
    }
}

.nav-link {
    letter-spacing: 1px;
}

.hero-img {
    background-size: cover;
    background-position: center center;
    height: 500px;
}

.dropdown-toggle::after {
    color: $primary;
    margin-left: 0 !important;
}

.dropdown-item:hover,
.dropdown-item:focus {
    background-color: $white;
    color: $primary;
}

footer {
    .col-lg-2 {
        &:nth-child(2) {
            .list {
                margin-left: 40px;
            }
        }
    }
}
