.social-media{
    a{
        padding: 0 5px;
        i{font-size:20px;}

    }

    &.colored{
        a.facebook{color:$facebook !important}
        a.twitter{color:$twitter !important }
        a.linkedin{color:$linkedin !important }
        a.instagram{color:$instagram !important }
        a.behance{color:$behance !important }
        a.youtube{color:$youtube !important }
    }

    &.hoverColored{
        a.facebook:hover{color:$facebook !important}
        a.twitter:hover{color:$twitter !important }
        a.linkedin:hover{color:$linkedin !important }
        a.instagram:hover{color:$instagram !important }
        a.behance:hover{color:$behance !important }
        a.youtube:hover{color:$youtube !important }
    }
    
}
