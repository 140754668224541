@font-face {
    font-family: "Gothic821 Cn BT";
    src: url("/assets/fonts/gothic.eot"); /* IE9*/
    src: url("/assets/fonts/gothic.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("/assets/fonts/gothic.woff2") format("woff2"),
        /* chrome firefox */ url("/assets/fonts/gothic.woff") format("woff"),
        /* chrome firefox */ url("/assets/fonts/gothic.ttf") format("truetype"),
        /* chrome firefox opera Safari, Android, iOS 4.2+*/
            url("/assets/fonts/gothic.svg#Gothic821 Cn BT") format("svg"); /* iOS 4.1- */
}

sub,
sup {
    font-size: 60%;
}
.gothic {
    font-family: "Gothic821 Cn BT";
}
.border-radius-xxl {
    border-radius: 20px;
}
.shadowed-bordered-radius {
    box-shadow: 1px 5px 5px 0 rgba(0, 0, 0, 0.35);
    border: 2px solid #000;
    border-radius: 30px;
}

.fs-12 {
    font-size: 12px !important;
}
.fs-14 {
    font-size: 14px !important;
}
.fs-13 {
    font-size: 13px !important;
}
.fs-14 {
    font-size: 14px !important;
}
.fs-15 {
    font-size: 15px !important;
}
.fs-16 {
    font-size: 16px !important;
}
.fs-18 {
    font-size: 18px !important;
}
.fs-20 {
    font-size: 20px !important;
}
.fs-24 {
    font-size: 24px !important;
}
.fs-36 {
    font-size: 36px !important;
}
.fs-48 {
    font-size: 48px !important;
}

.font-weight-500,
.fw-500 {
    font-weight: 500;
}
.font-weight-600,
.fw-600 {
    font-weight: 600;
}

.lh-10 {
    line-height: 1 !important;
}
.lh-11 {
    line-height: 1.1 !important;
}
.lh-12 {
    line-height: 1.2 !important;
}
.lh-13 {
    line-height: 1.3 !important;
}
.lh-14 {
    line-height: 1.4 !important;
}
.lh-15 {
    line-height: 1.5 !important;
}

.full-wh {
    height: 100vh;
}

.bg-wrapper {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.abs-full,
.bg-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.breadcrumb {
    // text-transform: uppercase;
    font-size: 17px;

    a {
        color: #000;
    }
    .active {
        a {
            font-weight: bold;
        }
    }
}

.content-wrapper {
    font-size: 17px;
    padding: 0px 2rem;
}

.content-block {
    padding: 1rem 0;

    &.big-content-block {
        padding: 1.5rem;
        border-radius: 20px;
        margin-bottom: 1.5rem;
    }
}

.hapadam-on {
    z-index: 10;
    position: relative;
}
