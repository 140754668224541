//HEADER
header {
    .logo {
        max-width: 200px;
        img {
            width: 100%;
        }
    }
    .content {
        margin-bottom: 40px;
    }
    .content-bar {
        .social {
            a {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $white;
                width: 26px;
                height: 26px;
                margin-left: 12px;
                text-decoration: none;
                border-radius: 50%;
                i {
                    color: $blue;
                    position: relative;
                }
            }
        }
    }
    .search-box {
        border: 1px solid #e0e4e9;
        background-color: #ecf0f5;
        padding: 3.5px 44px 3.5px 24px;
        border-radius: 50px;
        margin-top: 18px;
        position: relative;
        .search-input {
            border: none;
            font-size: 16px;
            background-color: #ecf0f5;
            outline: none;
            &::placeholder {
                text-align: center;
                font-size: 16px;
            }
        }
        i {
            position: absolute;
            right: 20px;
            color: #adadaf;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

//FOOTER
footer {
    background-color: $navy;
    padding: 76px 0px 56px 0px;
    position: relative;

    .f-logo {
        img {
            width: 90%;
        }
    }
    &::before {
        content: "";
        position: absolute;
        height: 13px;
        width: 100%;
        background: $blue;
        bottom: 0;
    }

    // .ss {
    //     span {
    //         font-size: 12px;
    //         color: $white;
    //     }
    // }

    .social {
        a {
            margin-right: 20px;
            &:last-child {
                margin-right: 0;
            }
            i {
                font-size: 20px;
                color: $white;
            }
        }
    }
    .bottom {
        .left {
            max-width: 600px;
        }
    }
    .uls {
        ul {
            ul &:first-child {
                padding-inline-start: 0;
            }
        }
        .list {
            width: 100%;
        }
    }
}
